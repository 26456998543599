/* eslint-disable no-plusplus */
<template>
  <div class="margin-top-64">
    <b-row class="pb-2">
      <b-col class="col-lg-12 text-center">
        <h1
          class="aiops_neutral_blue_heading1 text-center"
        >
          KPI TEIS Performance
        </h1>
      </b-col>
      <b-col class="col-lg-12 text-center padding-top-24">
        <h1
          class="aiops_mineral_green_heading2 text-center"
        >
          Tickets Recategorized
        </h1>
      </b-col>
      <b-col md="12">
        <vue-apex-charts
          type="bar"
          height="465"
          :options="columnChart.chartOptions"
          :series="columnChart.series"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-12 text-center pt-2 pb-2">
        <h2
          class="aiops_mineral_green_heading2"
        >
          Tickets Assignation to best resolution group
        </h2>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col
        class="col-md-6"
      >
        <b-col md="12">
          <h3
            class="aiops_label_16_3e7282_bold"
          >
            Tickets Reassignment
          </h3>
        </b-col>
        <b-col md="12">
          <vue-apex-charts
            type="bar"
            height="465"
            :options="columnChartTicketsReassign.chartOptions"
            :series="columnChartTicketsReassign.series"
          />
        </b-col>
      </b-col>
      <b-col
        class="col-lg-6"
      >
        <b-col md="12">
          <h3
            class="aiops_label_16_3e7282_bold"
          >
            Self-resolution Ratio
          </h3>
        </b-col>
        <b-col md="12">
          <vue-apex-charts
            type="line"
            height="465"
            :options="columnChart1.chartOptions"
            :series="columnChart1.series"
          />
        </b-col>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-12 text-center pt-2 pb-2">
        <h2
          class="aiops_mineral_green_heading2"
        >
          Tickets Pending
        </h2>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col
        class="col-md-6"
      >
        <b-col md="12">
          <h3
            class="aiops_label_16_3e7282_bold"
          >
            No. Ticket Prediction
          </h3>
        </b-col>
        <b-col md="12">
          <vue-apex-charts
            type="line"
            height="465"
            :options="apexChatData.lineChartSimple.chartOptions"
            :series="apexChatData.lineChartSimple.series"
          />
        </b-col>
      </b-col>
      <b-col
        class="col-lg-6"
      >
        <b-col md="12">
          <h3
            class="aiops_label_16_3e7282_bold"
          >
            Pending Monthly
          </h3>
        </b-col>
        <b-col md="12">
          <vue-apex-charts
            type="bar"
            height="465"
            :options="columnChartMonthlyPending.chartOptions"
            :series="columnChartMonthlyPending.series"
          />
        </b-col>
      </b-col>
    </b-row>
    <b-row
      class="margin-top-64"
    >
      <b-col class="d-flex justify-content-center flex-wrap pt-0 pb-4">
        <b-button
          class="ibop-mineral-green-button"
          @click="checkManagerHome"
        >
          <b-img
            class="mintui_arrow_right_circle_light"
          />
          <span class="align-middle aiops_label_white_14">See Operator Home Page</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormGroup,
  BImg,
} from 'bootstrap-vue'
import utilsAiops from '@/@core/utils/aiops/utils'
// import { isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'

const { dataGraphCategory, filterByCatLevel } = utilsAiops()

// colors
const chartColors = {
  column: {
    series1: '#232d4b',
    series2: '#00AA9B',
    series3: '#005573',
    bg: '#CCE3E4',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

const chartColorsBarServiceDesk = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

const level1Name = 'area'
const level2Name = 'categoryLevel2'
const level3Name = 'categoryLevel3'
const horizontalTypeGraph = 'horizontal'
const pieTypeGraph = 'pie'

export default {
  components: {
    VueApexCharts,
    flatPickr,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormGroup,
    BImg,
  },
  data() {
    return {
      apexChatData: {
        lineChartSimple: {
          series: [
            {
              name: 'Actual',
              data: [96.0, 107.0, 87.0, 8.0, 12.0, 118.0, 131.0, 125.0, 118.0, 47.0, 35.0, 25.0, 145.0, 115.0, 100.0, 87.0, 90.0, 106.0],
            },
            {
              name: 'Forescast',
              data: [80.0, 115.0, 60.0, 15.0, 10.0, 95.0, 135.0, 115.0, 97.0, 55.0, 23.0, 22.0, 130.0, 123.0, 101.0, 103.0, 79.0, 100.0, 95.0, 130.0, 153.0, 112.0],
            },
          ],
          chartOptions: {
            chart: {
              stacked: false,
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            legend: {
              show: true,
              position: 'top',
              fontSize: '14px',
              fontFamily: 'Open Sans,Helvetica,Arial,serif',
              horizontalAlign: 'left',
            },
            markers: {
              strokeWidth: 7,
              strokeOpacity: 1,
              strokeColors: [$themeColors.light],
              colors: [chartColors.column.series2, chartColors.column.series1],
            },
            colors: [chartColors.column.series2, chartColors.column.series1],
            dataLabels: {
              enabled: false,
              enabledOnSeries: [1],
            },
            tooltip: {
              enabled: true,
            },
            stroke: {
              width: [4, 4],
              curve: 'smooth',
              dashArray: [0, 3],
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: [
                '01/02',
                '02/02',
                '03/02',
                '04/02',
                '05/02',
                '06/02',
                '07/02',
                '08/02',
                '09/02',
                '10/02',
                '11/02',
                '12/02',
                '13/02',
                '14/02',
                '15/02',
                '16/02',
                '17/02',
                '18/02',
                '19/02',
                '20/02',
                '21/02',
              ],
            },
            yaxis: {
              // opposite: isRtl,
            },
          },
        },
      },
      dataTicketsForChart2: [],
      selectedL1: null,
      selectedL2: null,
      optionsL1: [],
      optionsL2: [],
      options: [],
      dataTickets: [],
      chartCategoryL1: {},
      chartCategoryL2: null,
      chartCategoryL3: null,
      chartCategoryL3T3: null,
      stockDataL1: [],
      stockDataL2: [],
      stockDataL3: [],
      stockDataL3T3: [],
      rangePicker: ['2019-05-01', '2019-05-10'],
      columnChart: {
        series: [
          {
            name: '% TEIS',
            data: [15, 22, 19, 29, 25, 20, 30],
          },
          {
            name: '% Manual categorization',
            data: [85, 78, 81, 71, 75, 80, 70],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.column.series2, chartColors.column.series1],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              colors: {
                backgroundBarColors: [
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                ],
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Open Sans,Helvetica,Arial,serif',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['12/02', '13/02', '14/02', '15/02', '16/02', '17/02', '18/02'],
          },
          yaxis: {
            max: 100,
            tickAmount: 5,
          },
          fill: {
            opacity: 1,
          },
        },
      },
      columnChartTicketsReassign: {
        series: [
          {
            name: '% Manual categorization',
            data: [412, 481, 583, 666, 512, 695, 930, 895, 793, 608, 532],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.column.series2, chartColors.column.series1],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Open Sans,Helvetica,Arial,serif',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['08/02', '09/02', '10/02', '11/02', '12/02', '13/02', '14/02', '15/02', '16/02', '17/02', '18/02'],
          },
          yaxis: {
            max: 1000,
            tickAmount: 5,
          },
          fill: {
            opacity: 1,
          },
        },
      },
      columnChart1: {
        series: [
          {
            name: 'SERVICEDESK',
            type: 'bar',
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          },
          {
            name: '% Self Resolution',
            data: [65, 69, 64, 66, 68, 63, 70, 76, 84, 87, 90],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          colors: [chartColors.column.series1, chartColors.column.series3],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              colors: {
                backgroundBarColors: [
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                ],
                backgroundBarRadius: 0,
              },
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Open Sans,Helvetica,Arial,serif',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['08/02', '09/02', '10/02', '11/02', '12/02', '13/02', '14/02', '15/02', '16/02', '17/02', '18/02'],
          },
          yaxis: {
            max: 100,
            tickAmount: 5,
          },
          fill: {
            opacity: 1,
          },
        },
      },
      latestBarChartTicketsReassigment: {
        data: {
          labels: ['08/02', '09/02', '10/02', '11/02', '12/02', '13/02', '14/02', '15/02', '16/02', '17/02', '18/02'],
          datasets: [
            {
              label: 'Tickets Reassigment',
              data: [412, 481, 583, 666, 512, 695, 930, 895, 793, 608, 532],
              backgroundColor: '#00AA9B',
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 1,
              barThickness: 20,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: true,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColorsBarServiceDesk.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 1000,
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
          },
        },
      },
      latestBarChartTicketsDailyPending: {
        data: {
          labels: ['01/01', '02/01', '03/01', '04/01', '05/01', '06/01', '07/01', '08/01', '09/01', '10/01', '11/01', '12/01', '13/01', '14/01', '15/01', '16/01', '17/01', '18/01', '19/01', '20/01', '21/01', '22/01', '23/01', '24/01', '25/01', '26/01', '27/01'],
          datasets: [
            {
              label: 'PENDING PER DAY',
              data: [7, 9, 6, 2, 7, 4, 6, 0, 4, 3, 1, 0, 2, 0, 0, 0, 2, 0, 0, 0, 1, 0, 2],
              backgroundColor: '#00AA9B',
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 1,
              barThickness: 20,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: true,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColorsBarServiceDesk.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                ticks: {
                  stepSize: 5,
                  min: 0,
                  max: 40,
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
          },
        },
      },
      columnChartMonthlyPending: {
        series: [
          {
            name: 'Pending Monthly',
            data: [20, 15, 18, 30, 23, 32, 28, 20, 15, 7, 2],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.column.series1],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Open Sans,Helvetica,Arial,serif',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['03/21', '04/21', '05/21', '06/21', '07/21', '08/21', '09/21', '10/21', '11/21', '12/21', '01/22'],
          },
          yaxis: {
            max: 40,
            tickAmount: 5,
          },
          fill: {
            opacity: 1,
          },
        },
      },
      latestBarChartTicketsMonthlyPending: {
        data: {
          labels: ['03/21', '04/21', '05/21', '06/21', '07/21', '08/21', '09/21', '10/21', '11/21', '12/21', '01/22', '02/22'],
          datasets: [
            {
              label: 'PENDING PER MONTH',
              data: [20, 15, 18, 30, 23, 32, 28, 20, 15, 7, 2],
              backgroundColor: chartColors.column.series1,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 1,
              barThickness: 20,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: true,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColorsBarServiceDesk.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColorsBarServiceDesk.grid_line_color,
                  zeroLineColor: chartColorsBarServiceDesk.grid_line_color,
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  max: 40,
                  fontColor: chartColorsBarServiceDesk.labelColor,
                },
              },
            ],
          },
        },
      },
    }
  },
  created() {
    this.welcomeAssist()
    // this.dataPieGraphCategoryL1()
    setTimeout(() => {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    }, 1000)
  },
  mount() {
  },
  methods: {
    asignDataTickets() {
      const { dataTickets } = this.$route.params
      if (!dataTickets) {
        this.checkManagerHome()
      }
      this.dataTickets = dataTickets
      this.dataTicketsForChart2 = this.dataTickets
      return this.dataTickets
    },
    dataPieGraphCategoryL1() {
      const dataPieLevel1 = dataGraphCategory(this.asignDataTickets(), level1Name, pieTypeGraph)
      this.stockDataL1 = dataPieLevel1.stockData
      this.chartCategoryL1 = dataPieLevel1.dataChart
      this.optionsL1 = dataPieLevel1.selectData
      this.welcomeAssist()
    },
    dataPieGraphCategoryL2(_areaValue) {
      this.selectedL2 = null
      this.chartCategoryL3 = null
      const dataPieLevel2 = dataGraphCategory(filterByCatLevel(this.dataTicketsForChart2, level1Name, _areaValue), level2Name, pieTypeGraph)
      // const dataPieLevel2 = dataGraphCategory(filterByCatLevel(this.asignDataTickets(), level1Name, _areaValue), level2Name, pieTypeGraph)
      this.stockDataL2 = dataPieLevel2.stockData
      this.chartCategoryL2 = dataPieLevel2.dataChart
      this.optionsL2 = dataPieLevel2.selectData
    },
    dataBarGraphCategoryL3(_catL2) {
      this.chartCategoryL3 = null
      const dataBarLevel3 = dataGraphCategory(filterByCatLevel(this.asignDataTickets(), level2Name, _catL2), level3Name, horizontalTypeGraph)
      this.stockDataL3 = dataBarLevel3.stockData
      this.chartCategoryL3 = dataBarLevel3.dataChart
      if (this.stockDataL3.length > 3) {
        this.stockDataL3 = this.stockDataL3.slice(0, 3)
        this.chartCategoryL3T3 = this.chartCategoryL3
        this.chartCategoryL3T3.data.labels = this.chartCategoryL3T3.data.labels.slice(0, 3)
        this.chartCategoryL3T3.data.datasets[0].backgroundColor = this.chartCategoryL3T3.data.datasets[0].backgroundColor.slice(0, 3)
        this.chartCategoryL3T3.data.datasets[0].data = this.chartCategoryL3T3.data.datasets[0].data.slice(0, 3)
      }
    },
    checkManagerHome() {
      this.$router.push({ name: 'aiops-home' })
    },
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', true)
    },
  },
}
</script>

<style lang="scss" scoped>

  .legend-chart {
    padding-left: 8px;
    padding-top: 3px;
    color: #fff;
    width: 16px !important;
    height: 16px !important;
    display: inline-block;
  }

</style>
